import Head from 'next/head'
import Layout from 'layouts/lost'

export default function Custom404() {
  return (
    <Layout>
      <Head>
        <title>Pureskills - Página não encontrada</title>
        <meta name="description" content="Pureskills - Página não encontrada" />
      </Head>

      <div className="error-wrapper">
        <div>
          <h1>404</h1>
          <div className="text">
            <h2>Esta página não foi encontrada.</h2>
          </div>
        </div>
      </div>
    </Layout>
  )
}

